import { Flex } from '../style/styledElements';
import Modal from './Modal';

function Loader() {
    
    return (
        <Flex
            position='fixed'
            bg='black'
            top='0'
            right='0'
            height='100vh'
            width='100vw'
            zIndex='10000'
            color='white'
            center
            fontSize='display2'
        >
            Loading...
        </Flex>
    );
}

export default Loader;
