import { Runner } from "../../style/styledComponents"
import { Flex, Li, Span } from "../../style/styledElements"

function Newsletter() {
  return (
      <Flex
          fontSize={{ xs: 'display4', lg: 'display4' }}
          overflow='hidden'
          color='gray'
      >
          <Runner py={{ xs: '3', lg: '4' }}>
              
              {[
                  'Injection Moulding',
                  'Injection Blow Moulding',
                  'Extrusion',
                  'Blow Moulding',
                  'Precision machined parts – Metal & Alloys',
                  'Sheet metal parts',
                  'Roto Moulding',
                  'Die casting',
                  'Precision Fabrication',
              ].map((x, i) => (
                  <Li
                      pr={{ xs: '7', lg: '9' }}
                      fontSize={{ xs: 'small', md: 'display5' }}
                      key={i}
                  >
                      {x}
                  </Li>
              ))}
              {[
                  'Injection Moulding',
                  'Injection Blow Moulding',
                  'Extrusion',
                  'Blow Moulding',
                  'Precision machined parts – Metal & Alloys',
                  'Sheet metal parts',
                  'Roto Moulding',
                  'Die casting',
                  'Precision Fabrication',
              ].map((x, i) => (
                  <Li
                      pr={{ xs: '7', lg: '9' }}
                      fontSize={{ xs: 'small', md: 'display5' }}
                      key={i}
                  >
                      {x}
                  </Li>
              ))}
              {[
                  'Injection Moulding',
                  'Injection Blow Moulding',
                  'Extrusion',
                  'Blow Moulding',
                  'Precision machined parts – Metal & Alloys',
                  'Sheet metal parts',
                  'Roto Moulding',
                  'Die casting',
                  'Precision Fabrication',
              ].map((x, i) => (
                  <Li
                      pr={{ xs: '7', lg: '9' }}
                      fontSize={{ xs: 'small', md: 'display5' }}
                      key={i}
                  >
                      {x}
                  </Li>
              ))}
              {[
                  'Injection Moulding',
                  'Injection Blow Moulding',
                  'Extrusion',
                  'Blow Moulding',
                  'Precision machined parts – Metal & Alloys',
                  'Sheet metal parts',
                  'Roto Moulding',
                  'Die casting',
                  'Precision Fabrication',
              ].map((x, i) => (
                  <Li
                      pr={{ xs: '7', lg: '9' }}
                      fontSize={{ xs: 'small', md: 'display5' }}
                      key={i}
                  >
                      {x}
                  </Li>
              ))}
          </Runner>
      </Flex>
  );
}

export default Newsletter