const radii = [
    '0px',
    '2px',
    '4px',
    '8px',
    '10px',
    '16px',
    '24px',
    '36px',
    '48px',
    '90px',
];

const space = [0, 4, 8, 16, 24, 32, 48, 64, 86, 128, 156, 200];

const theme = {
    breakpoints: {},

    space,
    fontSizes: {},
    // // lineHeights,
    // fonts: {
    //     normal: "'Nunito', sans-serif",
    //     heading: "'Nunito Sans', sans-serif",
    // },

    colors: {
        black: '#000',
        dark: '#181818',
        gray: '#828282',
        blue: '#0474F2',
        light: '#EEF0F4',
    },

    radii,
};

theme.fontSizes = {
    xs: '.75rem',
    sm: '.9rem',
    normal: '1rem',
    display6: '1.1rem',
    display5: '1.2rem',
    display4: '1.4rem',
    display3: '1.6rem',
    display2: '1.8rem',
    display1: '2.5rem',
    heading: '3rem',
};

theme.breakpoints.xs = '320px';
theme.breakpoints.sm = '480px';
theme.breakpoints.md = '767px';
theme.breakpoints.lg = '1025px';
theme.breakpoints.xl = '1200px';

export default theme;
