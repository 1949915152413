import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import { Div, Img } from './style/styledElements';
import Footer from './components/footer/Footer';
import Loader from './components/Loader';
import Hero from './asset/imagesComp/Hero.webp';

const Landing = React.lazy(() => import('./pages/landing/Index'));
const About = React.lazy(() => import('./pages/about/Index'));
const Services = React.lazy(() => import('./pages/services/Index'));
const Enquire = React.lazy(() => import('./pages/enquire/Index'));

function Layout() {
    const location = useLocation();
    const mainContent = React.useRef(null);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    return (
        <>
            <Img
                src={Hero}
                position='absolute'
                opacity='0'
                height='0'
                width='0'
            />
            <Navbar />
            <Div mt={{ xs: '96px', md: '120px' }} ref={mainContent}>
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route index element={<Landing />} />
                        <Route exact element={<About />} path='about' />
                        <Route exact element={<Services />} path='services' />
                        <Route exact element={<Enquire />} path='enquire' />
                    </Routes>
                </Suspense>
            </Div>
            <Footer />
        </>
    );
}

export default Layout;
